import { useEffect } from "react";
import { useState } from "react";

const mark = { X: "X", O: "O", none: undefined };

const valuesDefault = [
  [{ mark: mark.none }, { mark: mark.none }, { mark: mark.none }],
  [{ mark: mark.none }, { mark: mark.none }, { mark: mark.none }],
  [{ mark: mark.none }, { mark: mark.none }, { mark: mark.none }],
];

const action = {
  mark: "mark",
  makeMark: (setActions, square, mark) =>
    setActions((prev) => [...prev, { action: action.mark, mark, square }]),
};

const gameStates = {
  start: "start",
  deadHeat: "deadHeat",
  end: "end",
};

function TicTacToe() {
  const [values, setValues] = useState([...valuesDefault]);
  const [actions, setActions] = useState([]);

  // eslint-disable-next-line
  const [actionsMaked, setActionsMaked] = useState([]);
  const [toMark, setToMark] = useState(mark.X);
  const [gameOver, setGameOver] = useState(gameStates.start);
  const [marks, setMarks] = useState(0);

  const resetGame = () => {
    setValues([...valuesDefault]);
    setActions([]);
    setToMark(mark.X);
    setGameOver(gameStates.start);
    setMarks(0);
  };

  const changeMark = () => {
    if (toMark === mark.X) setToMark(mark.O);
    else setToMark(mark.X);
  };

  // New action, inspect if make a mark and if game is over.
  useEffect(() => {
    if (actions.length) {
      const lastAction = actions[actions.length - 1];
      const { square, mark } = lastAction;

      if (lastAction.action === action.mark) {
        // Make Mark
        if (values[square.row][square.col].mark === mark.none) {
          setValues((prev) =>
            prev.map((row, i) =>
              i !== square.row
                ? row
                : row.map((item, j) =>
                    j !== square.col ? item : { ...item, mark: mark }
                  )
            )
          );
          setMarks((prev) => ++prev);
          setActionsMaked((prev) => [...prev, lastAction]);
          // GameOver?
          if (gameLogic(values, square, mark, setValues))
            setGameOver(gameStates.end);
          else changeMark();
        }
      }
    }
    // eslint-disable-next-line
  }, [actions]);

  useEffect(() => {
    if (marks === 9 && gameOver !== gameStates.end)
      setGameOver(gameStates.deadHeat);
    // eslint-disable-next-line
  }, [marks]);

  const handleSquareClick = (square) => {
    if (gameOver === gameStates.start)
      action.makeMark(setActions, square, toMark);
  };

  // const prevActiion = () => {
  //   if (!actionsMaked.length) return;

  //   const lastAction = actions[actions.length - 1];
  //   const { square, mark } = lastAction;
  //   setValues((prev) =>
  //     prev.map((row, i) =>
  //       i !== square.row
  //         ? row
  //         : row.map((item, j) =>
  //             j !== square.col ? item : { ...item, mark: mark.none }
  //           )
  //     )
  //   );
  //   changeMark();
  //   setActionsMaked(
  //     actionsMaked.filter((item, i) => i !== actionsMaked.length - 1)
  //   );
  //   setMarks((prev) => --prev);
  //   setActions(actions.filter((item, i) => i !== actions.length - 1));
  // };

  return (
    <div className="container vh-100 vw-100 text-center p-5">
      <h1> Tic Tac Toe</h1>
      {gameOver === gameStates.end ? (
        <h2 className="fs-4 mt-5 text-success">
          <span className="text-danger">{toMark} </span> Wins
        </h2>
      ) : gameOver === gameStates.deadHeat ? (
        <h2 className="fs-4 mt-5 text-primary">Dead heat</h2>
      ) : (
        <h2 className="fs-4 mt-5">
          Turn of <span className="text-danger">{toMark}</span>
        </h2>
      )}
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div style={{ width: "10rem" }}></div>
        <table className="d-flex align-items-center justify-content-center my-5 mx-5">
          <tbody>
            {values.map((row, i) => (
              <tr key={i}>
                {row.map((item, j) => (
                  <td key={j}>
                    <Square
                      value={item.mark ? item.mark : undefined}
                      handleClick={() => handleSquareClick({ row: i, col: j })}
                      textColor={item.win ? "text-success" : ""}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Record list={actions} title="Record" />
      </div>
      {/* <button className="btn btn-outline-dark border-1" onClick={prevActiion}>
        Prev Action
      </button> */}
      <button className="btn btn-outline-dark border-1" onClick={resetGame}>
        New Game
      </button>
    </div>
  );
}

export default TicTacToe;

function Square({ value = undefined, handleClick, textColor }) {
  return (
    <button
      style={{ minHeight: "5rem", minWidth: "5rem", borderRadius: "12px" }}
      className={`btn btn-outline-dark border-1 fs-1 ${textColor}`}
      onClick={handleClick}
    >
      {value && value}
    </button>
  );
}

function Record({ title, list }) {
  useEffect(() => {
    const _this = document.getElementById(title);
    _this.scrollTo(0, _this.scrollHeight);
  });

  return (
    <div className="d-flex flex-column text-muted">
      <h1 className="fs-5">{title}</h1>
      <div
        style={{
          overflowY: "auto",
          height: "10rem",
          width: "10rem",
          borderRadius: "12px",
          scrollBehavior: "smooth",
        }}
        id={title}
        className="d-flex flex-column py-1 justify-content-center bg-light border"
      >
        {list.map(({ action, square, mark }, i) => (
          <p key={i}>
            {i === list.length - 1 && (
              <span className="text-danger"> {">"} </span>
            )}
            [{square.row},{square.col}] {action}{" "}
            <span className="text-danger">{mark}</span>
          </p>
        ))}
      </div>
    </div>
  );
}

function gameLogic(map, square, mark, setValues) {
  if (gameLogicTryAxis(map, square, mark, logic.axisX)) {
    paintSquareWins.axisX(square, setValues);
    return true;
  }

  if (gameLogicTryAxis(map, square, mark, logic.axisY)) {
    paintSquareWins.axisY(square, setValues);
    return true;
  }

  if (gameLogicTryAxis(map, square, mark, logic.axisXY)) {
    paintSquareWins.axisXY(setValues);
    return true;
  }

  if (gameLogicTryAxis(map, square, mark, logic.axisYX)) {
    paintSquareWins.axisYX(setValues);
    return true;
  }

  return false;
}

function gameLogicTryAxis(map, square, mark, axis) {
  const { row, col } = square;
  let i = 0;
  while (i < 3 && axis(i, col, map, row, mark)) i++;
  return i === 3;
}

const logic = {
  axisX: (i, col, map, row, mark) => i === col || map[row][i].mark === mark,

  axisY: (i, col, map, row, mark) => i === row || map[i][col].mark === mark,

  axisXY: (i, col, map, row, mark) =>
    (i === col && i === row) || map[i][i].mark === mark,

  axisYX: (i, col, map, row, mark) =>
    (i === col && 2 - i === row) || map[2 - i][i].mark === mark,
};

const paintSquareWins = {
  axisX: (square, setValues) =>
    setValues((prev) =>
      prev.map((row, i) =>
        i !== square.row ? row : row.map((item) => ({ ...item, win: true }))
      )
    ),

  axisY: (square, setValues) =>
    setValues((prev) =>
      prev.map((row) =>
        row.map((item, j) => (j !== square.col ? item : { ...item, win: true }))
      )
    ),

  axisXY: (setValues) =>
    setValues((prev) =>
      prev.map((row, i) =>
        row.map((item, j) => (i !== j ? item : { ...item, win: true }))
      )
    ),

  axisYX: (setValues) =>
    setValues((prev) =>
      prev.map((row, i) =>
        row.map((item, j) =>
          (i === 2 && j === 0) || (i === 1 && j === 1) || (i === 0 && j === 2)
            ? { ...item, win: true }
            : item
        )
      )
    ),
};
