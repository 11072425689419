import "../../App.css";

export function Button({ href, text, style, id }) {
  return (
    <a
      className="d-flex align-items-center justify-content-center m-1 my-2 button-t2 position-absolute"
      style={style}
      href={href}
      id={id}
    >
      {text}
    </a>
  );
}
