import "../../App.css";
//import { useState } from "react";
//import { Link } from "react-router-dom";
//import ImgCol from "../../components/ImgCol";

function Intro() {
  //sconst [mouseHoverBtn, setMouseHoverBtn] = useState(false);

  const removeOpacityAnimStart = (ev) => {
    document.getElementById(ev.target.id).classList.remove("opacityAnimStart");
  };

  return (
    <>
      <a
        href="https://agustinlemes.com"
        className="position-absolute w-100 vw-100 end-0 py-4 text-danger text-center bg-white fw-bold  text-decoration-none
        d-block"
      >
        New Agustín Lemes
        <i
          className="fas fa-arrow-up ms-2"
          style={{ transform: "rotate(45deg)" }}
        ></i>
      </a>

      <div
        style={{
          backgroundImage: `linear-gradient(#0000,#000C), url(imgs/landing/Fox.jpg)`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          zIndex: "-1",
          backgroundColor: "#1e1e1e",
        }}
        className={`fixed-top vh-100 dilateAnim`}
      ></div>
      <div
        style={{
          height: "100vh",
        }}
        className=""
      >
        <div
          style={{
            height: "100vh",
            width: "12rem",
            left: "3vw",
            backgroundColor: "#b1c37722",
          }}
          id="home"
          className="d-flex align-items-center justify-content-center position-absolute"
        >
          <div className="d-flex align-items-center justify-content-center position-absolute">
            <h1
              id="intro-h1"
              className="me-3 text-color-t1 dilateAnim text-vertical"
            >
              AGUSTIN
            </h1>
            <h2
              id="intro-h2"
              className="ms-3 text-color-t1 dilateAnim text-vertical"
            >
              LE<span id="ajustM">M</span>
              ES
            </h2>
          </div>
          <h3
            id="intro-h3"
            className="ms-3 text-color-t1 dilateAnim mt-auto mb-4 me-3"
          >
            Full Stack Developer
          </h3>
        </div>
        <a
          id="viewBestProjects"
          className="opacityAnimStart text-center d-flex align-items-center p-0 pb-3"
          onMouseEnter={removeOpacityAnimStart}
          href="#projects"
        >
          View Projects
        </a>
      </div>
    </>
  );
}

export default Intro;
