import "../../App.css";

export function Frame({ name, img, className }) {
  return (
    <div
      style={{
        height: "70vh",
        width: "25vw",
        backgroundImage: `linear-gradient(#032e4e55,#032e4e44)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      id="projects-frame"
      className="d-flex flex-column position-relative border"
    >
      <div
        style={{
          backgroundImage: `radial-gradient(#0000,#0000), url(${img})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#e1e1e1",
        }}
        className={`d-flex align-items-center justify-content-center h-100 w-100 flex-column ${className}`}
      ></div>
      <h4 className="text-color-t2 text-white w-100 fs-6 my-0 p-2 fw-light fw-bold text-center">
        {name}
      </h4>
    </div>
  );
}
