function LevelMap({ levels, level }) {
  return (
    <div
      style={{ minHeight: "20vh", minWidth: "50vw" }}
      className="mx-auto text-center p-2 rounded"
    >
      <h2 className="fs-4">Level</h2>
      <div className="d-flex flex-row my-4 justify-content-center align-items-center flex-wrap">
        <LevelToGraph first={true} on={level === 0} text={1} />
        {levels.map((lvl, index) =>
          index > 0 ? (
            <LevelToGraph key={index} on={level === index} text={index + 1} />
          ) : (
            ""
          )
        )}
      </div>
    </div>
  );
}

export default LevelMap;

function LevelToGraph({ first = false, on = false, text }) {
  return (
    <>
      {first ? (
        <div
          style={{ height: "3rem", width: "3rem" }}
          className={`rounded-circle outline ${
            on ? "border-crimson" : "border-dark border text-muted"
          }`}
        >
          {text ? text : ""}
        </div>
      ) : (
        <>
          <span
            style={{ width: "2rem" }}
            className="border-bottom border-dark"
          ></span>
          <div
            style={{ height: "3rem", width: "3rem" }}
            className={`rounded-circle outline ${
              on ? "border-crimson" : "border-dark border text-muted"
            }`}
          >
            {text ? text : ""}
          </div>
        </>
      )}
    </>
  );
}
