const INITIAL_STATE = {
  score: 0,
  cards: [],
  map: {},
  level: 0,
  cardsDiscovered: 0,
  end: false,
};
const SCORE_TO_SUM = 10;
const SCORE_TO_SUB = -4;

function gameReducer(state = INITIAL_STATE, action) {
  const currCard = action.payload;

  switch (action.type) {
    case "NEW_GAME":
      return { ...INITIAL_STATE };
    case "NEXT_LEVEL_GAME":
      return { ...state, level: state.level + 1, cardsDiscovered: 0 };
    case "END_GAME":
      return { ...state, end: true };
    case "ADD_MAP_GAME":
      return { ...state, map: action.payload };
    case "REMOVE_MAP_GAME":
      return { ...state, map: {} };
    case "SUM_SCORE_GAME":
      return { ...state, score: state.score + SCORE_TO_SUM };
    case "SUB_SCORE_GAME":
      return { ...state, score: state.score + SCORE_TO_SUB };
    case "ADD_CARD_GAME":
      return { ...state, cards: [...state.cards, action.payload] };
    case "REMOVE_CARDS_GAME":
      return { ...state, cards: [] };
    case "ADD_CARDS_DISCOVERED_GAME":
      return {
        ...state,
        cardsDiscovered: state.cardsDiscovered + action.payload,
      };
    case "SHOWED_CARD_GAME":
      return {
        ...state,
        map: {
          ...state.map,
          matrix: state.map.matrix.map((row) =>
            row.map((card) => ({ ...card, showed: true }))
          ),
        },
      };
    case "DISCOVERED_CARD_GAME":
      return {
        ...state,
        map: {
          ...state.map,
          matrix: state.map.matrix.map((row) =>
            row.map((card) => {
              if (card.row === currCard.row && card.col === currCard.col)
                return { ...card, discovered: true };
              else return card;
            })
          ),
        },
      };
    case "REVEAL_CARD_GAME":
      return {
        ...state,
        map: {
          ...state.map,
          matrix: state.map.matrix.map((row) =>
            row.map((card) => {
              if (card.row === currCard.row && card.col === currCard.col)
                return { ...action.payload };
              else return card;
            })
          ),
        },
      };
    case "REVEAL_CARDS_GAME":
      return {
        ...state,
        map: {
          ...state.map,
          matrix: state.map.matrix.map((row) =>
            row.map((card) => ({ ...card, reveal: action.payload }))
          ),
        },
      };
    default:
      return state;
  }
}

export default gameReducer;
