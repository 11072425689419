import { useSelector } from "react-redux";
import "./RenderMap.css";
import Tile from "./Tile";

function RenderMap({ map = [] }) {
  const game = useSelector((state) => state.game);
  return (
    <>
      {map.map((row, index) => (
        <div
          className={`row ${game.end ? "px-2 border-crimson" : ""}`}
          key={index}
        >
          {row.map((tile, index) => (
            <Tile key={index} tile={tile} />
          ))}
        </div>
      ))}
    </>
  );
}

export default RenderMap;
