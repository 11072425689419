import "../../App.css";
import Intro from "../../components/landing/Intro";
import Projects from "../../components/landing/Projects";
import End from "../../components/landing/End";
//import { useState } from "react";
//import { Link } from "react-router-dom";
//import ImgCol from "../../components/ImgCol";

function Home() {
  //sconst [mouseHoverBtn, setMouseHoverBtn] = useState(false);
  return (
    <>
      <Intro />
      <Projects />
      <End />
    </>
  );
}

export default Home;
