import { useState } from "react";
import "./ImgCol.css";

function ImgCol({
  maxHeight = "70vh",
  src = "",
  imgPos = "center",
  animImg = false,
}) {
  const [moveImg, setMoveImg] = useState(false);
  function animImgF() {
    setMoveImg("cardMoveImg");
    setTimeout(() => setMoveImg("d-none"), 4000);
  }
  return (
    <div
      style={{
        height: maxHeight,
        width: "5rem",
        minWidth: "5rem",
        maxWidth: "5rem",
        borderRadius: "12px",
        backgroundColor: src ? "#0c0c0c" : "",
        backgroundImage: `linear-gradient(#0000,#000), url(${src})`,
        backgroundPosition: imgPos,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={`col p-0 mx-2 my-2 border-0 ${moveImg}`}
      onClick={animImg ? animImgF : () => {}}
    ></div>
  );
}

export default ImgCol;
