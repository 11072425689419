/* Tiles */
const landTile = (letterNum) => ({ name: String.fromCharCode(letterNum) });

/* Map Seeder */
export function mapSeeder(rows, cols) {
  const firstLetterNum = "A".charCodeAt();
  const lastLetterNum = "Z".charCodeAt();
  const res = [];
  let letters = [];

  if ((rows * cols) / 2 > lastLetterNum - firstLetterNum) return [];

  for (let i = 0; i < (rows * cols) / 2; i++) {
    letters.push(landTile(firstLetterNum + i));
  }

  letters = [...letters, ...letters];
  letters = messArray(letters);

  let k = 0;
  for (let i = 0; i < rows; i++) {
    res.push([]);
    for (let j = 0; j < cols; j++) {
      res[i].push({ ...letters[k], row: i, col: j });
      k++;
    }
  }
  return { matrix: res, rows, cols };
}

function messArray(array) {
  const res = [];
  const aux = [...array];

  while (aux.length) {
    const randomNum = Math.floor(Math.random() * aux.length);
    const itemRemoved = aux.splice(randomNum, 1)[0];
    res.push(itemRemoved);
  }

  return res;
}

export const strToMap = (str) => {
  const res = str.split("");
  return {
    matrix: [
      res.map((letter) => ({
        name: letter,
        reveal: true,
      })),
    ],
  };
};
