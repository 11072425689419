import "../App.css";

function NoMatch() {
  return (
    <div className="container fs-3 mt-5 pt-5 d-flex align-items-center justify-content-center">
      <i className="fas fa-exclamation-triangle fs-3 text-danger me-2 mb-1"></i>
      <h1 className="text-dark fs-4">Page not found :c</h1>
    </div>
  );
}

export default NoMatch;
