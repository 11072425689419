import "../../App.css";
import { Button } from "./Button";
import ImgCol from "./ImgCol";

function End() {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(#0000, #000c), url(imgs/landing/Lienzo.jpg)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#fff",
        opacity: "1",
      }}
      id="Me&Technologies"
      className="vh-100 position-relative"
    >
      <div className="d-flex align-items-center justify-content-center flex-column py-2 w-100">
        <h1 className="text-color-t2 mt-4">ME & TECH</h1>
        <h3 id="projects-h3" className="text-color-t2">
          Thank You For Your Time
        </h3>
      </div>
      <i id="end-tech" className="row   py-5 mx-0 fs-6">
        <div className="col-md-4 d-flex align-items-center justify-content-center flex-column my-4">
          <i className="fab fa-react"> React & Redux</i>
          <i className="fab fa-html5"> HTML </i>
          <i className="fab fa-css3-alt"> CSS </i>
          <i className="fab fa-js-square"> JavaScript </i>
          <i className="fab fa-bootstrap"> Bootstrap </i>
        </div>
        <div className="col-md-4 d-flex align-items-center justify-content-center flex-column my-4">
          <span>
            Version control:
            <i className="ms-2 fab fa-github"> Git </i>
          </span>
          Framework: Scrum
        </div>
        <div className="col-md-4 d-flex align-items-center justify-content-center flex-column my-4">
          <i className="fab fa-node"> NodeJS & ExpressJS</i>
          <span> MySQL & MongoDB </span>
          <span> API & API REST </span>
        </div>
      </i>
      <div style={{ bottom: "0" }} className="d-flex position-absolute">
        <ImgCol animImg={true} src={"imgs/home/me.png"} maxHeight="30vh" />
        <ImgCol animImg={true} src={"imgs/home/me2.png"} maxHeight="30vh" />
        <ImgCol animImg={true} src={"imgs/home/me3.png"} maxHeight="30vh" />
      </div>
      <Button href="#projects" text="Back" id="end-button-back" />
    </div>
  );
}

export default End;
