import Tile from "./Tile";

function Help() {
  return (
    <div
      style={{ minHeight: "20vh", minWidth: "50vw" }}
      className="mx-auto text-center p-2 outline text-info rounded"
    >
      <h2>How To play?</h2>
      <h3 className="fs-5">
        <span className="text-danger">¡Click</span> the{" "}
        <span className="text-danger">squares</span> and{" "}
        <span className="text-danger">Find</span> the{" "}
        <span className="text-danger">Pair.</span> Have fun!
      </h3>
      <div className="d-flex flex-row my-4 justify-content-center">
        <Tile tile={{ name: "A", reveal: true }} />
        <Tile tile={{ name: "+", reveal: true, color: "#0dcaf0" }} />
        <Tile tile={{ name: "A", reveal: true }} />
        <Tile tile={{ name: "=", reveal: true, color: "#0dcaf0" }} />
        <Tile tile={{ name: "+10", reveal: true, color: "#3D3" }} />
      </div>

      <div className="d-flex flex-row my-4  justify-content-center">
        <Tile tile={{ name: "A", reveal: true }} />
        <Tile tile={{ name: "+", reveal: true, color: "#0dcaf0" }} />
        <Tile tile={{ name: "B", reveal: true }} />
        <Tile tile={{ name: "=", reveal: true, color: "#0dcaf0" }} />
        <Tile tile={{ name: "-4", reveal: true, color: "#D33" }} />
      </div>
    </div>
  );
}

export default Help;
