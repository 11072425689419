import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import gameActions from "../../redux/actions/gameActions";

function GameMenu({ name, ...props }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNewGame = () => {
    dispatch(gameActions.newGame());
    setShow(false);
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="m-2 fs-5 px-2 py-1 position-absolute end-0 top-0 rounded-0"
      >
        <i className="fas fa-compass me-2 fs-6"></i>
        {name}
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="game p-3 m-0 vw-25"
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="fs-3">{name}</Offcanvas.Title>
          <button onClick={handleClose} className="fs-5 py-2 danger">
            Close
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <p className="fs-6">
            Every game has random cards, so you can play it again and again.
          </p>
          <div
            style={{ maxWidth: "10rem" }}
            className="fixed-bottom ms-4 mb-2 d-flex align-items-start flex-column fs-5"
          >
            <button onClick={handleNewGame}>
              <i className="fas fa-feather-alt me-2"></i>New Game
            </button>
            <Link to="/" className="danger">
              <i className="fas fa-sign-out-alt me-2"></i>
              Quit
            </Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default GameMenu;
