import { Link } from "react-router-dom";
import "../../App.css";
import { Button } from "./Button";
import { Frame } from "./Frame";

function Projects() {
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(#0000, #000c), url(imgs/landing/projects-bg.jpg)`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#1e1e1e",
          opacity: "1",
        }}
        id="projects"
        className="vh-100 d-flex flex-row"
      >
        <div
          style={{
            height: "100vh",
            width: "6rem",
            backgroundColor: "#032e4e99",
          }}
          className="d-flex align-items-center justify-content-center flex-column"
        >
          <h1
            id="projects-h1"
            className="text-color-t2 text-vertical text-color pt-5 text-white"
          >
            PROJECTS
          </h1>
          <h3 id="projects-h3" className="text-color-t2 mb-4 text-white">
            The Bests
          </h3>
        </div>
        <div
          id="projects-div-projects"
          className="d-flex align-items-center justify-content-center h-100 w-100"
        >
          <div className="position-relative">
            <span
              className="badge bg-danger position-absolute start-0 w-100 rounded-0 top-50"
              style={{ zIndex: 1 }}
            >
              In maintenance
            </span>
            <button className="cardExpand text-u-none btn" disabled>
              <Frame name="E-Commerce" img="imgs/landing/E-commerce.png" />
            </button>
          </div>
          {/* <a
            href="https://e-commerce-front-amber.vercel.app"
            rel="noreferrer"
            target="_blank"
            className="cardExpand mx-4 text-u-none"
          >
            <Frame name="E-Commerce" img="imgs/landing/E-commerce.png" />
          </a> */}
          <a
            href="https://movieflix-agus.vercel.app"
            rel="noreferrer"
            target="_blank"
            className="cardExpand mx-4 text-u-none"
          >
            <Frame name="MovieFlix" img="imgs/landing/Movieflix.png" />
          </a>
          <Link to="/game" className="cardExpand mx-4 text-u-none">
            <Frame name="Memorize" img="imgs/landing/projects-memorize.png" />
          </Link>
        </div>
        <Button href="#home" text="Home" id="projects-button-home" />
        <Button href="#Me&Technologies" text="M&T" id="projects-button-myt" />
      </div>
    </>
  );
}

export default Projects;
