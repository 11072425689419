import "../App.css";
import "./Game.css";
import RenderMap from "../components/game/RenderMap";
import GameMenu from "../components/game/GameMenu";
import { useSelector, useDispatch } from "react-redux";
import gameActions from "../redux/actions/gameActions";
import { useEffect } from "react";
import { mapSeeder, strToMap } from "../components/game/mapSeeder/mapSeeder";
import Help from "../components/game/Help";
import LevelMap from "../components/game/LevelMap";
import TimerBar from "./TimerBar";
const levels = [
  { rows: 2, cols: 2 },
  { rows: 3, cols: 2 },
  { rows: 4, cols: 3 },
  { rows: 4, cols: 4 },
  { rows: 5, cols: 4 },
  { rows: 6, cols: 5 },
  { rows: 6, cols: 6 },
];

function Game() {
  const { score, cards, map, level, cardsDiscovered, end } = useSelector(
    (store) => store.game
  );
  const dispatch = useDispatch();
  const endMap = strToMap("Thanks for playing!");

  const notShowCardsAgainAfterReloadPage = () => {
    map &&
      map.matrix &&
      !map.matrix[0][0].showed &&
      dispatch(gameActions.showCards());
  };

  const generateAndAddNewMap = (map) => {
    if (map && map.matrix) return;
    if (level === levels.length) {
      dispatch(gameActions.endGame());
    } else if (map && !map.matrix) {
      const { rows, cols } = levels[level];
      const newMap = mapSeeder(rows, cols);
      dispatch(gameActions.addMap(newMap));
    }
  };

  const removeSelectedCards = () => dispatch(gameActions.removeCards());

  const newGame = (map) => {
    removeSelectedCards();
    generateAndAddNewMap(map);
  };

  /* Start */
  useEffect(() => {
    notShowCardsAgainAfterReloadPage();

    return () => {
      dispatch(gameActions.newGame());
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(() => newGame(map), [map]);

  /* Next Level */
  useEffect(() => {
    if (cardsDiscovered === map.rows * map.cols) {
      dispatch(gameActions.nextLevel());
      dispatch(gameActions.removeMap());
    }
    // eslint-disable-next-line
  }, [cardsDiscovered]);

  /* Game Logic */
  useEffect(() => {
    if (end) return;
    if (cards.length >= 2) {
      if (cards[0].name === cards[1].name) {
        cards.map((card) => dispatch(gameActions.cardDiscovered(card)));
        dispatch(gameActions.sumScore());
        dispatch(gameActions.addCardDiscovered(2));
        dispatch(gameActions.cardsReveal(true));
        setTimeout(() => {
          dispatch(gameActions.cardsReveal(false));
        }, 100);
      } else {
        cards.map((card) =>
          dispatch(gameActions.cardReveal({ ...card, reveal: false }))
        );
        dispatch(gameActions.subScore());
      }
      dispatch(gameActions.removeCards());
    }
    // eslint-disable-next-line
  }, [cards]);

  const handleNewGame = () => {
    dispatch(gameActions.newGame());
  };

  return (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
      }}
      className="opacityAnim game d-flex flex-column"
    >
      <GameMenu name="Menu" />
      <h1 className="mx-auto">
        Score{" "}
        <span className={score <= 0 ? "text-danger" : "text-success"}>
          {score}
        </span>
      </h1>

      {!end ? (
        <>
          {/* Game */}
          <TimerBar />

          <div className="mx-auto my-auto">
            <RenderMap map={map.matrix} />
          </div>

          {level < 2 && <Help />}
          {level >= 2 && <LevelMap levels={levels} level={level} />}
        </>
      ) : (
        <>
          <div className="mx-auto my-auto">
            <RenderMap map={endMap.matrix} />
          </div>

          {level === levels.length ? (
            // Show win
            <div className="mx-auto my-auto">
              <p className="text-success">You Win!</p>
            </div>
          ) : (
            // Show try again
            <div className="mx-auto my-auto fs-5">
              <button onClick={handleNewGame}>
                <i className="fas fa-redo me-2"></i>Try Again
              </button>
            </div>
          )}

          <LevelMap levels={levels} level={level} />
        </>
      )}
    </div>
  );
}

export default Game;
