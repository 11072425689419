import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./pages/landing/Home";
import NoMatch from "./pages/NoMatch";
import Game from "./pages/Game";
import TicTacToe from "./pages/TicTacToe";
// import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/game" component={Game} />
          <Route exact path="/game/tic-tac-toe" component={TicTacToe} />
          <Route component={NoMatch} />
        </Switch>
      </Router>
      <ToastContainer />
      {/* <Analytics /> */}
    </>
  );
}

export default App;
